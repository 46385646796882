<template>
  <div v-if="user" class="user-profile--information pt-5">
    <v-row>
      <v-col cols="12" sm="12">
        <!-- <div> -->
        <div>
          <v-row>
            <v-col>
              <div class="d-flex justify-space-between align-center flex-wrap">
                <h1 class="headline primary--text">
                  Questionnaire Data

                  <span v-if="user.initial_profile && hasProgramms">
                    <edit-user-profile-dialog
                      :profile="user.initial_profile"
                      :update-profile-api="updateUserProfile"
                    />
                  </span>
                </h1>
              </div>
            </v-col>
          </v-row>

          <v-row v-if="user.initial_profile && hasProgramms">
            <v-col cols="12" sm="3">
              <h1 class="subtitle-2 grey--text">
                New Goal
              </h1>

              <p class="body-1 primary--text font-weight-bold">
                {{
                  user.initial_profile.target_goal | readableEnums(targetGoals)
                }}
              </p>
            </v-col>

            <v-col cols="12" sm="3">
              <h1 class="subtitle-2 grey--text">
                Body type
              </h1>

              <p class="body-1 primary--text font-weight-bold">
                {{ user.initial_profile.body_type | readableEnums(bodyTypes) }}
              </p>
            </v-col>

            <v-col cols="12" sm="3">
              <h1 class="subtitle-2 grey--text">
                Physical Activity
              </h1>

              <p class="body-1 primary--text font-weight-bold">
                {{
                  user.initial_profile.physical_activity
                    | readableEnums(physicalActivities)
                }}
              </p>
            </v-col>

            <v-col cols="12" sm="3">
              <h1 class="subtitle-2 grey--text">
                Workout Frequencies
              </h1>

              <p class="body-1 primary--text font-weight-bold">
                {{
                  user.initial_profile.workout_frequency
                    | readableEnums(workoutFrequencies)
                }}
              </p>
            </v-col>

            <v-col cols="12" sm="3">
              <h1 class="subtitle-2 grey--text">
                Macro Split
              </h1>

              <p class="body-1 primary--text font-weight-bold">
                {{
                  user.initial_profile.macro_split | readableEnums(bodyTypes)
                }}
              </p>
            </v-col>

            <v-col cols="12" sm="3">
              <h1 class="subtitle-2 grey--text">
                Previous BBR Girl:
              </h1>

              <p class="body-1 primary--text font-weight-bold">
                False
              </p>
            </v-col>

            <v-col cols="12" sm="3">
              <h1 class="subtitle-2 grey--text">
                Previous Goal
              </h1>

              <p class="body-1 primary--text font-weight-bold">
                {{
                  user.initial_profile.calorie_target_goal
                    | readableEnums(targetGoals)
                }}
              </p>
            </v-col>

            <v-col cols="12" sm="3">
              <h1 class="subtitle-2 grey--text">
                Previous Calories
              </h1>

              <p class="body-1 primary--text font-weight-bold">
                {{ user.initial_profile.calories_consumption || 0 }} kcal
              </p>
            </v-col>

            <v-col cols="12" sm="3">
              <h1 class="subtitle-2 grey--text">
                Restrictions
              </h1>

              <p class="body-1 primary--text font-weight-bold">
                {{
                  user.initial_profile.restrictions
                    | readableEnums(restrictions)
                }}
              </p>
            </v-col>

            <v-col cols="12" sm="3">
              <h1 class="subtitle-2 grey--text">
                Goals
              </h1>

              <p class="body-1 primary--text font-weight-bold">
                <span
                  v-if="
                    user.initial_profile.biggest_goals &&
                      user.initial_profile.biggest_goals.length
                  "
                >
                  {{ user.initial_profile.biggest_goals.join(', ') }}
                </span>
              </p>
            </v-col>

            <v-col cols="12" sm="3">
              <h1 class="subtitle-2 grey--text">
                Obstacles
              </h1>

              <p class="body-1 primary--text font-weight-bold">
                <span
                  v-if="
                    user.initial_profile.personal_obstacles &&
                      user.initial_profile.personal_obstacles.length
                  "
                >
                  {{ user.initial_profile.personal_obstacles.join(', ') }}
                </span>
              </p>
            </v-col>

            <v-col cols="12" sm="3">
              <h1 class="subtitle-2 grey--text">
                Onboarding Date
              </h1>

              <p class="body-1 primary--text font-weight-bold">
                {{
                  user.initial_profile.onboarding_completed_at | formatedDate
                }}
              </p>
            </v-col>
          </v-row>

          <v-row class="mt-10" v-if="user.initial_profile">
            <v-col cols="12" sm="3">
              <h1 class="subtitle-2 grey--text">
                Age
              </h1>

              <p class="body-1 primary--text font-weight-bold">
                {{ user.initial_profile.age }} years
              </p>
            </v-col>

            <v-col cols="12" sm="3">
              <h1 class="subtitle-2 grey--text">
                Height
              </h1>

              <p class="body-1 primary--text font-weight-bold">
                {{ user.initial_profile.height }} cm
              </p>
            </v-col>

            <v-col cols="12" sm="3">
              <h1 class="subtitle-2 grey--text">
                Weight
              </h1>

              <p class="body-1 primary--text font-weight-bold">
                {{ user.initial_profile.weight }} kg
              </p>
            </v-col>

            <v-col cols="12" sm="3">
              <h1 class="subtitle-2 grey--text">
                Chest
              </h1>

              <p class="body-1 primary--text font-weight-bold">
                {{ user.initial_profile.chest }} cm
              </p>
            </v-col>

            <v-col cols="12" sm="3">
              <h1 class="subtitle-2 grey--text">
                Waist
              </h1>

              <p class="body-1 primary--text font-weight-bold">
                {{ user.initial_profile.waist }} cm
              </p>
            </v-col>

            <v-col cols="12" sm="3">
              <h1 class="subtitle-2 grey--text">
                Hips
              </h1>

              <p class="body-1 primary--text font-weight-bold">
                {{ user.initial_profile.hip }} cm
              </p>
            </v-col>

            <v-col cols="12" sm="3">
              <h1 class="subtitle-2 grey--text">
                Arms
              </h1>

              <p class="body-1 primary--text font-weight-bold">
                {{ user.initial_profile.arms }} cm
              </p>
            </v-col>

            <v-col cols="12" sm="3">
              <h1 class="subtitle-2 grey--text">
                Legs
              </h1>

              <p class="body-1 primary--text font-weight-bold">
                {{ user.initial_profile.legs }} cm
              </p>
            </v-col>
          </v-row>

          <no-list
            v-if="!user.initial_profile && !hasProgramms"
            details="No Questionnaire Data"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import _ from 'lodash'

import NoList from '@/components/elements/NoList'
import EditUserProfileDialog from '../components/EditUserProfileDialog'
import { mdiCheckCircle } from '@mdi/js'

export default {
  name: 'UserPersonalInformationTab',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    EditUserProfileDialog,
    NoList,
  },

  data() {
    return {
      icons: {
        check: mdiCheckCircle,
      },
    }
  },

  computed: {
    ...mapState({
      bodyTypes: (state) => state.meals.extra.bodyTypes,
      physicalActivityTypes: (state) =>
        state.users.extra.types.physicalActivities,
      restrictions: (state) => state.users.extra.types.restrictions,
      targetGoals: (state) => state.users.extra.types.targetGoals,
      workoutFrequencies: (state) => state.users.extra.types.workoutFrequencies,
      physicalActivities: (state) => state.users.extra.types.physicalActivities,

      user: (state) => state.users.selectedUser,
      programs: (state) => state.users.allUserPrograms,
      selectedProgram: (state) => state.users.selectedUserProgram,
    }),

    hasProgramms() {
      return _.size(this.programs) > 0
    },
  },

  methods: {
    ...mapActions({
      updateUserProfile: 'users/updateUserProfile',
    }),
  },
}
</script>

<style lang="scss">
.user-profile {
  &--basic {
    width: 50%;
    padding: 0 10px 0 10px;

    &:last-child {
      width: auto;
    }
  }

  ul {
    padding-left: 0;
    list-style: none;

    li {
      color: var(--v-grey-base) !important;

      &:not(:first-child) {
        color: var(--v-primary-base) !important;
        font-weight: 600 !important;
      }
    }
  }

  .questionnaire {
    &--item {
      width: 25%;
    }

    &--nodata {
      height: 200px;
    }
  }
}
</style>
